<template>
  <b-card title="Hak Akses">
      <b-button size="md" variant="primary" @click="add()"  v-if="$can('add', 'roles')">
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        /><span>Tambah</span>
      </b-button>
    
    <!-- search input -->
    <div class="custom-search float-right">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Cari</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            name="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <div v-if="!rows || isLoadingTable" class="text-center">
        <b-spinner label="Tunggu..."></b-spinner>
    </div>
    <vue-good-table
      v-if="rows && !isLoadingTable"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="editModal(props.row.id)"  v-if="$can('edit', 'roles')">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Ubah</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeModal(props.row.id)"  v-if="$can('delete', 'roles')">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Hapus</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Data 1 hingga
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> dari {{ props.total }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  <!-- Modals -->
    <b-modal
      id="modal-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showFormModal"
    >
      <template #modal-header="{}">
        <h5>{{ (isEdit) ? "Ubah" : "Tambah" }} {{ pageName }}</h5>
      </template>

      <div v-if="isLoadingForm" class="text-center">
          <b-spinner label="Tunggu..."></b-spinner>
      </div>

      <b-form v-if="!isLoadingForm" @submit.prevent="save()">
        <b-form-group>
          <label for="name">Nama:</label>
          <b-form-input
            id="name"
            name="text"
            v-model="currentObject.name"
            placeholder="Nama Hak Akses"
          />
        </b-form-group>

        <b-form-group>
          <label for="permissions">Hak Akses:</label>
          <b-form-checkbox
            v-model="currentObject.manage_all"
            :disabled="currentObject.no_access"
            @change="selectAll"
          >
            Semua Akses
          </b-form-checkbox>

          <b-form-checkbox
            v-model="currentObject.no_access"
            :disabled="currentObject.manage_all"
            @change="unselectAll"
          >
            Tanpa Akses Admin Panel
          </b-form-checkbox>

          <div style="max-height: 360px; overflow: auto;" class="mt-1">
            <h4 class="mb-1">Akses Halaman</h4>
            <!-- <b-form-checkbox
              v-model="allSelected"
              :indeterminate="indeterminate"
              @change="toggleAll"
              :disabled="currentObject.manage_all || currentObject.no_access"
            >
              {{ allSelected ? 'Tidak Pilih Semua' : 'Pilih Semua' }}
            </b-form-checkbox> -->
            <div v-for="(group, key) in currentObject.permissionsGrouped" :key="key" class="mt-1">
              <h5>{{ capitalize(key) }}</h5>
              <b-form-checkbox-group
                v-model="currentObject.permissions"
                :options="group"
                class="mt-1"
                :disabled="currentObject.manage_all || currentObject.no_access"
                inline
              />
            </div>
          </div>

          <!-- <b-form-checkbox-group
            v-model="currentObject.permissions"
            :options="permissions"
            class="mt-1"
            :disabled="currentObject.manage_all || currentObject.no_access"
            inline
          /> -->
        </b-form-group>

      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Batal
        </b-button>
        <b-button size="md" variant="primary" @click="save()" :disabled="!isValid() || isLoadingForm">
          {{ (!isLoadingForm) ? "Simpan" : "Tunggu..." }}
        </b-button>
      </template>


    </b-modal>

  <!-- Modals -->
    <b-modal
      id="modal-delete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteModal"
    >
      <template #modal-header="{}">
        <h5>Hapus {{ pageName }}</h5>
      </template>

      <p>
        Apakah anda yakin untuk menghapus data ini?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Batal
        </b-button>
        <b-button size="md" variant="danger" @click="remove()" :disabled="isLoadingDelete">
          {{ (isLoadingDelete) ? "Tunggu..." : "Hapus" }}
        </b-button>
      </template>


    </b-modal>

  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BFormCheckbox, BFormCheckboxGroup
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number',
        },
        {
          label: 'Nama',
          field: 'name',
        },
        {
          label: 'Tgl Dibuat',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Aksi',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',

      isLoadingTable: false,
      isLoadingForm: false,
      isLoadingDelete: false,
      formState: "Edit",
      isEdit: false,
      showFormModal: false,
      showDeleteModal: false,
      pageName: "Hak Akses",

      currentObject: {
        id: -1,
        name: '',
        permissions: [],
        manage_all: false,
        no_access: false,
        permissionsGrouped: {}
      },
      selected: [],
      permissions: [],
      allSelected: false,
      indeterminate: false,
    }
  },
  computed: {
  },
  watch: {
    selected(newValue, oldValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.permissions.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  },
  methods: {
    selectAll() {
      this.currentObject.permissions = []

      this.currentObject.no_access = false
    },
    unselectAll() {
      this.currentObject.permissions = []
      this.currentObject.manage_all = false
    },
     capitalize(word) {
        const lower = word.toLowerCase()

        return word.charAt(0).toUpperCase() + lower.slice(1)
    },
    add() {
        this.reset()

        this.isEdit = false
        this.showFormModal = true

    },
    editModal(id) {
        this.reset()

        this.isLoadingForm = true

        this.isEdit = true
        this.showFormModal = true

        this.$http.get('/admin/v1/roles/' + id)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              const currentData = res.data.data
            
              this.currentObject.id = id
              this.currentObject.name = currentData.name
              this.currentObject.permissions = currentData.permissions

              if(currentData.permissions.indexOf('manage all') > -1) {
                this.currentObject.manage_all = true
                this.currentObject.no_access = false
              }
              else
              if(this.currentObject.permissions.length == 0) {
                this.currentObject.no_access = true
                this.currentObject.manage_all = false
              }
              else {
                this.currentObject.manage_all = false
                this.currentObject.no_access = false
              }
            }

            this.isLoadingForm = false
      })

    },
    removeModal(id) {      
      this.showDeleteModal = true

      this.currentObject.id = id
    },
    reset() {
      this.currentObject.name = ''
      this.currentObject.permissions = []
      this.currentObject.manage_all = false
      this.currentObject.no_access = false
      this.currentObject.id = -1
    },
    remove() {
      this.isLoadingDelete = true

      const id = this.currentObject.id

      this.$http.delete('/admin/v1/roles/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sukses`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }

          this.showDeleteModal = false

          this.isLoadingDelete = false

          this.fetchData()
      })
    },
    save() {
      if(this.currentObject.manage_all) {
        this.currentObject.permissions = ['manage all']
        this.currentObject.no_access = false
      }
      else
      if(this.currentObject.no_access) {
        this.currentObject.permissions = []
        this.currentObject.manage_all = false
      }

      const requestData = {
        'name': this.currentObject.name,
        'permissions': this.currentObject.permissions,
      }
      
      if(this.isEdit) {
        const id = this.currentObject.id

        this.$http.put('/admin/v1/roles/' + id, requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Sukses`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })

              this.fetchData()
            }

            this.isLoadingForm = false
        })

      }
      else {
        this.$http.post('/admin/v1/roles', requestData)
          .then(res => {
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Sukses`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })

              this.fetchData()
            }

            this.isLoadingForm = false
        })
      }

      this.showFormModal = false
    },
    fetchPermissions() {
      this.$http.get('/admin/v1/permissions')
        .then(res => {         
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
            this.permissions = []

            currentData.forEach((item, index) => {
              if(item.name === 'manage all') {
                return
              }

              const text_split = item.name.split(' ')

              if(text_split.length == 2) {
                if(this.currentObject.permissionsGrouped[text_split[1]] === undefined) {
                  this.currentObject.permissionsGrouped[text_split[1]] = []
                }

                this.currentObject.permissionsGrouped[text_split[1]].push({
                  text: this.capitalize(text_split[0]),
                  value: item.name
                })

              }

              this.permissions.push({
                text: item.name,
                value: item.name
              })
            })
          
            // console.log(JSON.stringify(this.permissions))
          }

          this.isLoadingForm = false
     })
    
    },
    fetchData() {
      this.isLoadingTable = true

      this.$http.get('/admin/v1/roles')
        .then(res => {         
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
          
            this.rows = currentData
          }

          this.isLoadingTable = false

     })
    },
    isValid() {
      return this.currentObject.name !== null && this.currentObject.name !== ""
    }
  },
  created() {
    this.fetchData()
    this.fetchPermissions()
  },
}
</script>
<style>
  .custom-checkbox {
    margin-bottom: 8px;
  }
</style>